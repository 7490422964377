<template>
  <div >
    <div>
      <v-layout>
     
        <page-title>Plans</page-title>
      </v-layout>

      <v-layout>
       
        <page-subtitle>
          Plans allow you to use more models, servers and transfer more data. You can upgrade or downgrade your plan at any time.
        </page-subtitle>
      </v-layout>

      <!-- Error message -->
      <v-layout>
        <div v-if="error">
          <v-alert type="error" dense outlined>
            {{ error }}
          </v-alert>
        </div>
      </v-layout>

      <v-layout class="mt-0">
        <div class="tbcard">
          <div>
            <v-layout row>
              <v-flex
                xs12
                md4
                lg4
                v-for="(plan, i) in billingPlans"
                :key="i"
                class="mt-5"
              >
                <v-card class="mx-auto mb-3 mr-3 ml-3" flat outlined>
                  <v-card-title class="headline">
                    {{ plan.name }}
                    <v-spacer></v-spacer> ${{ plan.price / 100}}
                  </v-card-title>
                  <v-card-text>
                    <p>
                      {{ plan.description }}
                    </p>
                    <v-simple-table>
                      <template v-slot:default>
                     
                        <tbody>
                          <tr>
                            <td>Image Generations (per month)</td>
                            <td class="text-right">
                              {{ plan.metadata.image_gens }}
                            </td>
                          </tr>

                          <tr>
                            <td>Models</td>
                            <td class="text-right">
                              {{ plan.metadata.models }}
                            </td>
                          </tr>
                          <tr>
                            <td>Servers</td>
                            <td class="text-right">
                              {{ plan.metadata.servers }}
                            </td>
                          </tr>                          

                          <tr>
                            <td>Storage (GB)</td>
                            <td class="text-right">
                              {{ plan.metadata.storage_gb }}
                            </td>
                          </tr>

                          <tr>
                            <td>QoS</td>
                            <td class="text-right">
                              <svg class="inline-flex fill-emerald-400" width="12" height="9" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" fill="#34D399" fill-rule="nonzero" />
                              </svg>
                            </td>
                          </tr>

                          <tr>
                            <td>Automatic load-balancing</td>
                            <td class="text-right">
                              <svg class="inline-flex fill-emerald-400" width="12" height="9" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" fill="#34D399" fill-rule="nonzero" />
                              </svg>
                            </td>
                          </tr>

                          <tr>
                            <td>Retries</td>
                            <td class="text-right">
                              <svg v-if="plan.metadata.retries === 'true'" class="inline-flex fill-emerald-400" width="12" height="9" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" fill="#34D399" fill-rule="nonzero" />
                              </svg>

                              <svg v-else class="inline-flex error" width="14" height="2" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 0v2H0V0z" fill-rule="evenodd" />
                              </svg>
                            </td>
                          </tr>

                          <tr>
                            <td>Custom storage</td>
                            <td class="text-right">
                              <svg v-if="plan.metadata.custom_storage === 'true'" class="inline-flex fill-emerald-400" width="12" height="9" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.28.28 3.989 6.575 1.695 4.28A1 1 0 0 0 .28 5.695l3 3a1 1 0 0 0 1.414 0l7-7A1 1 0 0 0 10.28.28Z" fill="#34D399" fill-rule="nonzero" />
                              </svg>

                              <svg v-else class="inline-flex fill-slate-500" width="14" height="2" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14 0v2H0V0z" fill-rule="evenodd" />
                              </svg>
                            </td>
                          </tr>
                          
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="primary mr-3"
                      outlined
                      :loading="loading"
                      :disabled="(billingAccount && billingAccount.stripePlanId === plan.id ||
                       !billingAccount && plan.name === 'Free') ? true : false"
                      @click="selectPlan(plan)"
                    >
                      <!-- <v-icon class="mr-1">touch_app</v-icon> -->
                      <span v-if="billingAccount && billingAccount.stripePlanId === plan.id">
                        Current
                      </span>
                      <span v-else>
                        Select
                      </span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<style lang="stylus">
.tbcard {
  width: 100%;
  display: block;
}
</style>


<script>
import PageTitle from '../PageTitle';
import PageSubtitle from '../PageSubtitle';

export default {
  components: {
    PageTitle,
    PageSubtitle
  },

  data() {
    return {

    }
  },

  computed: {
    project() {
      return this.$store.state.user.user?.projects[0]
    },
    billingPlans() {
      return this.$store.state.billing.billingPlans
    },
    billingAccount() {
      return this.$store.state.billing.billingAccount
    },
    loading() {
      return this.$store.state.billing.loading
    },
    error() {
      return this.$store.state.billing.error
    }
  },

  mounted() {
    this.refresh()
  },

  methods: {
    async refresh() {
      this.$store.dispatch('ListBillingPlans')

      await this.$store.dispatch('GetUser')
      
      this.$store.dispatch('GetBillingAccount', {
        projectId: this.project.id,
      })
    },
    async selectPlan(selectedPlan) {
      // This will either subscribe to a plan or will redirect us
      // to the Stripe checkout page
      await this.$store.dispatch('ChangePlan', {
        projectId: this.project.id,
        planId: selectedPlan.id,
      })

      if (!this.error) {
        // Wait for 2 seconds and then call refresh() function
        // to update the billing account
        setTimeout(() => {
          this.refresh()
        }, 1000)

        this.billingPlans.forEach(plan => {
          if (plan.id === selectedPlan.id) {
            this.$store.dispatch('Notify', `Subscribed to ${plan.name}`)
          }
        });


      }
    },
  }
}
</script>
